@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import '~bootstrap/scss/bootstrap';

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-size: 18px;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[inert] {
  opacity: 0.5;
}

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
