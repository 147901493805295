.loading {
  animation: spin 4s linear infinite;

  &-s {
    @extend .loading;
  }

  &-md {
    @extend .loading;
    width: 20px;
    height: 20px;
  }

  &-lg {
    @extend .loading;
    width: 36px;
    height: 36px;
  }

  &-inherit {
    @extend .loading;
    width: inherit;
    height: inherit;
  }
}

@-moz-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
