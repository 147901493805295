.database-download-window {
  backdrop-filter: blur(4px);
  z-index: 1000000;
}

.download-window-wrapper {
  min-height: 60vh;
}

.wait-message {
  animation: 'flick 2s infinite';
}

.border-title {
  flex: 0 0 150px;
}
