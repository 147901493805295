.error-root {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.error-block {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 20px;
    width: 700px;
    height: 200px;
    border: 1px solid #0b5ed7;
    border-radius: 8px;
    align-items: center;
}

.refresh {
    border: none;
    color:#0b5ed7 ;
    transition: all 0.5s;
    background-color: #FFF;
    &:hover {
        cursor: pointer;
        transform: rotate(-180deg);
    }
    &:active {
        transform: scale(0.9);
    }
}

.icon {
    color: #0b5ed7;
}