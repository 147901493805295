.sider {
  display: flex;
  height: 100%;

  @media screen and (max-width: 425px) {
    width: 200px;
  }
}

ul {
  border-inline-end: none !important;
}
