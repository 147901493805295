#notify-container {
  position: absolute;
  width: 30%;
  bottom: 0;
  right: 0;
  padding: 10px;
  z-index: 10000;

  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100vh;
  overflow: auto;
}

.not-success {
  background-color: rgba(21, 180, 21, 0.85) !important;
  color: white !important;
}

.not-error {
  background-color: rgba(243, 47, 47, 0.85) !important;
  color: white !important;
}

.close-notification {
  transform: translateX(110%);
  opacity: 0;
}

@media screen and (max-width: 720px) {
  #notifyContainer {
    width: 100%;
  }
}
