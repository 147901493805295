
.newKptFormButtons {
  display: flex;
  justify-content: space-around;
}

.progressLoading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}