.flex-half-width {
  flex: 0 0 calc(50% - 10px);
}

.flex-full-width {
  flex: 0 0 calc(100% - 10px);
}

.button-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.additional-text {
  opacity: 0.9;
  font-size: 0.8em;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 4px;
}

.circle-sm {
  @extend .circle;
  width: 10px;
  height: 10px;
}

.circle-md {
  @extend .circle;
  width: 24px;
  height: 24px;
}

.circle-lg {
  @extend .circle;
  width: 36px;
  height: 36px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.centered {
  display: block;
  margin: auto;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.abs-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown-hide-arrow {
  &:after {
    display: none !important;
  }
}

.max-width-50 {
  max-width: 50vw;
}

.max-height-25 {
  max-height: 25vw;
}

.grow-150 {
  flex: 1 0 150px;
}

.grow-250 {
  flex: 1 0 250px;
}

.grow-50 {
  flex: 1 0 50px;
}

.no-grow {
  flex: 0 0;
}

.cursor-pointer {
  cursor: pointer;
}

.better-card {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.chip {
  border-radius: 10px;
  padding: 4px 6px;
  height: min-content;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-pre-wrap {
  white-space: pre-wrap;
}


.button-shadow {
  box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
}